import { CPromise } from "c-promise2";

export function Fly(leftWing, rightWing, index) {
  this._id = `BUTTERFLY-#${index}#`;

  this.index = index;

  this.leftWingStart = leftWing.ref;
  this.rightWingStart = rightWing.ref;

  this.leftWingStart.setAttribute("bName", `${this._id}-LEFT`);
  this.rightWingStart.setAttribute("bName", `${this._id}-RIGHT`);

  this.leftWingStart.setAttribute("name", "b");
  this.rightWingStart.setAttribute("name", "b");

  this.leftWingStart.classList.add("preButterfly");
  this.rightWingStart.classList.add("preButterfly");
}

Fly.prototype.startAnimation = function () {
  this.parent.animate(hoverAnim, {
    duration: 450 + Math.round(Math.random() * 300),
    iterations: Infinity,
    easing: "ease-in-out",
  });

  const duration = 300 + Math.random() * 100;

  const startAnimL = this.leftWingStart.animate(flapAnimForward, {
    duration,
    iterations: Infinity,
  });

  const startAnimR = this.rightWingStart.animate(flapAnimBackward, {
    duration,
    iterations: Infinity,
    direction: "reverse",
  });

  return Promise.all([startAnimL.ready, startAnimR.ready]);
};

Fly.prototype.endAnimation = function () {
  const animations = this.parent.getAnimations({ subtree: true });

  animations[0].cancel();
  animations[1].cancel();

  animations[2].cancel();
};

export const createButterflys = async (canvas, scene) => {
  [scene.distCoordinates, ,] = await Promise.all([
    scene.getCoordinatePromise,
    scene.offsetLeftPromise,
  ]);

  console.log(scene);

  const l = [];
  const r = [];

  const { all } = canvas;

  for (let i = 0; i < all.length; i += 1) {
    switch (all[i].character) {
      case "\\":
        l.push(all[i]);
        continue;
      case "/":
        r.push(all[i]);
        continue;
      default:
    }
  }

  const butterflys = [];

  let x;

  for (x = 0; x < r.length; x += 1) {
    butterflys.push(new Fly(l[x], r[x], x));
  }

  l.slice(butterflys.length).forEach((n) => {
    n.ref.setAttribute("name", "hideme");
  });

  scene.butterflys = butterflys;

  return Promise.resolve(scene);
};

export const distributeButterflys = async (scene) => {
  return scene.positions().then(async (pos) => {
    const promises = [];

    for (let i = 0; i < scene.butterflys.length; i += 1) {
      const butterfly = scene.butterflys[i];

      let cL, cR;

      for (let z = 0; z < pos.length; z += 1) {
        const attr = pos[z].target.getAttribute("bname");
        if (attr.includes(butterfly._id)) {
          if (attr.includes("LEFT")) cL = pos[z].boundingClientRect;
          if (attr.includes("RIGHT")) cR = pos[z].boundingClientRect;
        }
      }

      if (!cL || !cR) continue;

      const toCoords = scene.distCoordinates[i];

      butterfly.x = toCoords.x;
      butterfly.y = toCoords.y;

      const duration = 900 + Math.round(Math.random() * 10);

      const leftAnimation = butterfly.leftWingStart.animate(
        {
          transform: `translate(${toCoords.x - cL.x - 13}px, ${
            toCoords.y - cL.y
          }px)`,
        },
        {
          duration: duration,
          delay: 350 + i * 5,
          iterations: 1,
          easing: "ease",
          fill: "forwards",
        }
      );

      const rightAnimation = butterfly.rightWingStart.animate(
        {
          translate: `${toCoords.x - cR.x}px ${toCoords.y - cR.y}px`,
        },
        {
          duration: duration,
          delay: 350 + i * 5,
          iterations: 1,
          easing: "ease",
          fill: "forwards",
        }
      );

      butterfly.anims = [leftAnimation, rightAnimation];

      promises.push(
        Promise.all([leftAnimation.finished, rightAnimation.finished])
      );
    }

    await Promise.all(promises);
    return scene;
  });
};

const hoverAnim = [
  { transform: "translateY(0px)" },
  { transform: "translateY(3px)" },
  { transform: "translateY(-1px)" },
];

const flapAnimForward = [
  { transform: "rotateY(25deg) rotateZ(10deg)" },
  { transform: "rotateY(-15deg) rotateZ(-13deg)" },
  { transform: "rotateY(25deg) rotateZ(11deg)" },
];

const flapAnimBackward = [
  { transform: "rotateY(-25deg) rotateZ(-10deg)" },
  { transform: "rotateY(15deg) rotateZ(13deg)" },
  { transform: "rotateY(-25deg) rotateZ(-11deg)" },
];

export const initButterflys = async (scene) => {
  const animHolderOffsetLeft = scene.holderOffsetLeft;
  scene.canvas.ref.classList.add("out");

  scene.canvas.fadeOutPromise = new Promise((resolve, reject) => {
    scene.canvas.ref.onanimationend = () => {
      resolve();
    };
  });

  await scene.canvas.fadeOutPromise.then(() => scene.canvas.ref.remove());

  for (let i = 0; i < scene.butterflys.length; i += 1) {
    const butterfly = scene.butterflys[i];
    const parentDiv = document.createElement("div");

    butterfly.xCenter =
      butterfly.x + (butterfly.x - (butterfly.x + 12)) - animHolderOffsetLeft;

    butterfly.anims[0].cancel();
    butterfly.anims[1].cancel();

    parentDiv.appendChild(butterfly.leftWingStart);
    parentDiv.appendChild(butterfly.rightWingStart);

    parentDiv.classList.add("butterfly");
    butterfly.parent = parentDiv;

    butterfly.startAnimation();

    parentDiv.style.translate = `${butterfly.xCenter}px ${butterfly.y}px`;

    scene.butterflyHolder.appendChild(parentDiv);
  }

  scene.canvas.other[0].current.appendChild(scene.butterflyHolder);

  return scene;
};
