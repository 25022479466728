import { lch, hsl } from "d3-color";

export const generateColorHSL = (color, MAX_ITER) => {
	if (color === MAX_ITER) return [0, 0, 0];

	const { r, g, b } = hsl(360 * (color / MAX_ITER), 1, 0.5).rgb();

	return [r, g, b];
};

export const generateColorLCH = (color, MAX_ITER) => {
	if (color === MAX_ITER) return [0, 0, 0];

	const s = color / MAX_ITER;
	const v = 1.0 - Math.pow(Math.cos(Math.PI * s), 2);
	const t = 75 * v;

	const { r, g, b } = lch(
		75 - t,
		28 + (75 - t),
		Math.pow(360 * s, 1.5) % 360
	).rgb();

	return [r, g, b];
};

export const generateColorRGB = (color, MAX_ITER) => {
	if (color === MAX_ITER) return [255, 255, 255];

	return [
		(color / MAX_ITER) * 255,
		(color / MAX_ITER) * 255,
		(color / MAX_ITER) * 255,
	];
};

export const generateColorTrippy = (color, MAX_ITER) => {
	if (color === MAX_ITER) return [0, 0, 0];

	const { r, g, b } = lch(
		(color / MAX_ITER) * Math.LOG2E * 75,
		360,
		(1 / Math.log(color / MAX_ITER)) * 360
	).rgb();
	return [r, g, b];
};
