import React, { useEffect, useRef, useState } from "react";
import styles from "./menu.module.css";
import { socials } from "../landing/ButterflyIntro";
import ListItem from "./components/listItem";
import Category from "./components/category";

const Socials = () => {
  return (
    <div className="social_holder">
      {socials.map((n, i) => (
        <img
          className="social_img"
          style={{
            transition: "0.5s opacity",
            opacity: 0,
            cursor: "pointer",
          }}
          src={n.src}
          name={i}
          ind={i}
          key={n.name}
          flyto="true"
          width="30px"
          height="30px"
          onClick={() => window.open(n.url)}
          alt={n.name}
          to={n.name}
        />
      ))}
    </div>
  );
};

const getCategories = (options) => {
  const categorised = options.reduce((prevValue, currentValue) => {
    const { category } = currentValue;

    prevValue[category] ||= [];
    prevValue[category].push(currentValue);

    return prevValue;
  }, {});

  return categorised;
};

const ListOptions = ({ options, selectedOption, setSelectedOption, title }) => {
  const isSelectedOption = (option) => selectedOption?.id === option.id;

  const categories = getCategories(options || []);

  const [hidden, setHidden] = useState(true);

  const unhide = () => setHidden(false);

  useEffect(() => {
    window.addEventListener("_unhide_menu", unhide);

    return () => {
      window.removeEventListener("_unhide_menu", unhide);
    };
  }, []);

  return Object.keys(categories).map((category, index) => (
    <Category
      isSelectedOption={isSelectedOption}
      hidden={hidden}
      title={category}
      setSelectedOption={setSelectedOption}
      options={categories[category] || []}
    />
  ));
};

export const Menu = ({
  refs,
  isFinished,
  setSelectedOption,
  selectedOption,
  options,
}) => {
  const socialParent = useRef(null);
  return (
    <div className={styles.menu_wrapper}>
      <div className={styles.menu_holder} ref={refs.holder}>
        <div className={styles.title}>
          {"wiseshiba".split("").map((n, index) => (
            <span
              className={styles.letter}
              style={{ opacity: isFinished.current === true ? "1" : "0" }}
              key={`letter-${index}${n}${Math.random()}`}
              flyto="true"
            >
              {n}
            </span>
          ))}
        </div>
        <div></div>
        <div id="socials" className={styles.socials} ref={socialParent}>
          <Socials
            sref={refs.socials}
            textHolder={socialParent}
            isFinished={isFinished}
          />
        </div>
        <ListOptions
          options={options}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          title="Image"
        />
      </div>
    </div>
  );
};
