import { tree } from "./util";
import { CPromise } from "c-promise2";

export const createTree = (scene) => {
  const doc = new DocumentFragment();

  const els = [];

  for (let i = 0; i < tree.length; i += 1) {
    if (tree[i] !== "%") {
      doc.append(tree[i]);
      continue;
    }

    if (i < 960) {
      const el = document.createElement("span");
      el.innerText = "%";

      els.push(el);
      doc.append(el);
    } else {
      doc.append("%");
    }
  }

  scene.perches = els;

  return doc;
};

export const showTree = (scene) =>
  new CPromise((resolve) => {
    const tree = createTree(scene);
    const treeHolder = scene.canvas.other[2];

    treeHolder.current.onanimationend = () => {
      scene.canvas.other[1].current.classList.remove("treeshake");
      scene.setPerchLocations(scene.perches);
      scene.pcb.then(() => {
        resolve(scene);
      });
    };

    treeHolder.current.append(tree);
    treeHolder.current.classList.add("treeup");

    scene.canvas.other[1].current.classList.add("treeshake");
  });
