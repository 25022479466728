import logo from "./logo.svg";
import "./App.css";
import LandingView from "./views/landing/LandingView";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const generateSizing = (ownerState, _theme) => {
  return {
    padding: (() => {
      switch (ownerState.size) {
        case "small":
          return "4px 14px";
        case "medium":
        default:
          return "5px 18px";
        case "large":
          return "6px 20px";
      }
    })(),
    fontSize: (() => {
      switch (ownerState.size) {
        case "small":
          return _theme.typography.pxToRem(13);
        case "medium":
        default:
          return _theme.typography.pxToRem(16);
        case "large":
          return _theme.typography.pxToRem(18);
      }
    })(),
  };
};

function App() {
  const theme = createTheme({
    typography: { fontFamily: "Lato" },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: "ws_primary" },
            style({ ownerState, theme: _theme }) {
              return {
                background: "#E71D36",
                fontWeight: 600,
                verticalAlign: "middle",
                color: "white",
                "&:hover": {
                  background: "#E71D36",
                  backgroundColor: "#E71D36",
                },
                ...generateSizing(ownerState, _theme),
              };
            },
          },
          {
            props: { variant: "ws_secondary" },
            style({ ownerState, theme: _theme }) {
              return {
                background: "#1E91D6",
                fontWeight: 600,
                color: "white",
                "&:hover": {
                  background: "#1E91D6",
                  backgroundColor: "#1E91D6",
                },
                ...generateSizing(ownerState, _theme),
              };
            },
          },
        ],
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        name="main"
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgb(32, 32, 32)",
        }}
      >
        <LandingView />
      </div>
    </ThemeProvider>
  );
}

export default App;
