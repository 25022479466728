import { Fly } from "./butterfly";

export const initButterflysStatic = async (scene) => {
  scene.canvas.ref.innerText = "";
  scene.setPerchLocations(scene.perches);
  const [, offsetLeft] = await Promise.all([
    scene.pcb,
    scene.offsetLeftPromise,
  ]);

  const left = document.createElement("span");
  const right = document.createElement("span");

  left.innerText = "\\";
  right.innerText = "/";

  for (let i = 0; i < scene.perches.length; i += 1) {
    const butterfly = new Fly(
      { ref: left.cloneNode(true) },
      { ref: right.cloneNode(true) },
      i
    );
    scene.butterflys.push(butterfly);
    const perch = scene.perchLocations[i];
    const parentDiv = document.createElement("div");

    const { x, y } = perch.boundingClientRect;

    butterfly.leftWingStart.style.translate = "";
    butterfly.rightWingStart.style.translate = "";

    parentDiv.appendChild(butterfly.leftWingStart);
    parentDiv.appendChild(butterfly.rightWingStart);

    butterfly.xCenter = butterfly.x + (butterfly.x - (butterfly.x + 12));

    parentDiv.style.translate = `${Math.round(x - offsetLeft) - 5}px ${
      Math.round(y) - 3
    }px`;

    parentDiv.classList.add("butterfly-no-anim");

    butterfly.parent = parentDiv;

    scene.butterflyHolder.appendChild(parentDiv);
  }

  scene.canvas.other[0].current.appendChild(scene.butterflyHolder);

  return scene;
};

export const createButterfliesManually = async (scene) => {
  await initButterflysStatic(scene);
  const menu = scene.canvas.other[3].current.children;
  [...menu[0].children, menu[1]].forEach((n) => {
    n.style.transition = "none";
    n.style.opacity = 1;
  });
  [...menu[2].children[0].children].forEach((n) => {
    n.style.opacity = 1;
  });
  scene.canvas.other[4].current = true;
};
