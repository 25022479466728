import React, { useEffect, useReducer, useRef, useState } from "react";
import { birdScene } from "../animation/intro/butterfly";
import { logo, logoSpaces, TextCanvas, tree } from "../animation/util";

import insta from "../../assets/insta.png";
import linkedin from "../../assets/linkendin.png";
import github from "../../assets/github.png";
import email from "../../assets/email.png";
import facebook from "../../assets/facebook.png";

import styles from "./styles.css";
import { Menu } from "../menu/menu";

export const socials = [
  {
    name: "linkedin",
    url: "https://www.linkedin.com/in/pierrewiseshiba/",
    src: linkedin,
  },
  { name: "github", url: "https://github.com/pharmac1st", src: github },
  {
    name: "instagram",
    url: "https://www.instagram.com/wiseshibaweb/",
    src: insta,
  },
  { name: "email", url: "mailto:hello@wiseshiba.net", src: email },
  {
    name: "facebook",
    url: "https://www.facebook.com/profile.php?id=100088869318072",
    src: facebook,
  },
];

const ButterflyIntro = ({ after, refs, mobile }) => {
  const animationRef = useRef(null);

  const wrapperRef = useRef(null);
  const treeHolder = useRef(null);
  const treeWrapper = useRef(null);
  const flyWrapper = useRef(null);

  const { textHolder, isFinished, animHolderRef } = refs;

  const [width, setWidth] = useState(window.innerWidth);

  const handleScreenResize = (e) => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreenResize);

    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };
  }, []);

  const createTextCanvas = () =>
    new TextCanvas(
      wrapperRef.current,
      flyWrapper,
      treeWrapper,
      treeHolder,
      textHolder,
      isFinished,
      animHolderRef
    );

  useEffect(() => {
    const tC = createTextCanvas();
    animationRef.current = tC.setAnimation(birdScene, after);

    return () => {
      animationRef.current.cancel();
    };
  }, [wrapperRef]);

  const textCanvasStyles = {
    width: "100%",
    height: "100%",
    whiteSpace: "pre",
    fontFamily: "Courier New",
    color: "#E71D36",
    margin: 0,
    fontWeight: "600",
    float: "left",
  };

  const fontsizes = {
    smaller: "10px",
    small: "15px",
    medium: "20px",
    large: "30px",
    xl: "60px",
  };

  const shouldScale = width > 500;

  return (
    <>
      <div
        ref={wrapperRef}
        style={{
          ...textCanvasStyles,
          fontSize: shouldScale ? fontsizes.medium : fontsizes.small,
          position: "absolute",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ transform: "translateX(20%)" }}>
          {logo.map((n, index) =>
            n !== "_" && n !== " " ? (
              <span key={`n${n}${index}`}>{n}</span>
            ) : (
              <span name="hideme" key={`DELETE-${n}-${index}`}>
                {n}
              </span>
            )
          )}
        </div>
      </div>

      <div
        style={{
          ...textCanvasStyles,
          fontSize: shouldScale ? fontsizes.medium : fontsizes.small,
          position: "absolute",
          pointerEvents: "none",
          WebkitTextSizeAdjust: "100%",
        }}
        ref={flyWrapper}
      ></div>
      <div
        style={{
          ...textCanvasStyles,
          color: "green",
          fontSize: shouldScale ? fontsizes.medium : fontsizes.small,
          WebkitTextSizeAdjust: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={treeWrapper}
      >
        <div ref={treeHolder}></div>
      </div>
    </>
  );
};

export default ButterflyIntro;
