import React, { useState, useRef, useEffect } from "react";
import { Menu } from "../menu/menu";
import { QbistGenerator } from "../tools/Qbist/Qbist";

import ButterflyIntro from "./ButterflyIntro";
import styles from "./landingstyles.module.css";

import qbistT from "../../assets/qbist-thumbnail.png";
import fractal from "../../assets/fractalBanner.webp";
import MobileMenu from "../menu/mobileMenu";
import FractalGenerator from "../tools/Fractal";

const Overlay = ({ children, mobile }) => {
	return (
		<div className={mobile ? styles.overlay_mobile : styles.overlay}>
			{children}
		</div>
	);
};

const LandingView = (animation) => {
	const textHolder = useRef(null);
	const socialsRef = useRef(null);
	const isFinished = useRef(false);

	const animHolderRef = useRef(null);
	const animationFinishedRef = useRef(false);

	const [mobile, setMobile] = useState();

	const animations = [
		{
			name: "The Dream Tree",
			component: (
				<ButterflyIntro
					refs={{ textHolder, socialsRef, isFinished, animHolderRef }}
					after={() => {
						animationFinishedRef.current = true;
						window.dispatchEvent(new CustomEvent("_unhide_menu"));
					}}
					mobile={mobile}
				/>
			),
		},
	];

	const menuOptions = [
		{
			id: "qbist",
			name: "QBist",
			component: <QbistGenerator />,
			mobileComponent: <></>,
			src: qbistT,
			category: "Image",
		},
		{
			id: "fractal",
			name: <span style={{ color: "white" }}>Fractal</span>,
			component: <FractalGenerator />,
			mobileComponent: <></>,
			src: fractal,
			category: "Image",
		},
	];

	const [selectedOption, setSelectedOption] = useState(undefined);

	const checkIfMobile = () => {
		const check =
			document.body.clientHeight <= 896 && document.body.clientWidth <= 414;

		setMobile(check);
	};

	useEffect(() => {
		checkIfMobile();
		window.addEventListener("resize", checkIfMobile);

		return () => {
			window.removeEventListener("resize", checkIfMobile);
		};
	}, []);

	return (
		<div
			className={styles.holder}
			style={mobile ? { display: "flex", flexDirection: "column" } : {}}
		>
			{!mobile ? (
				<Menu
					refs={{ holder: textHolder, socials: socialsRef }}
					isFinished={isFinished}
					options={menuOptions}
					setSelectedOption={setSelectedOption}
					selectedOption={selectedOption}
				/>
			) : (
				<MobileMenu
					refs={{ holder: textHolder, socials: socialsRef }}
					options={menuOptions}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
				/>
			)}
			{!mobile && (
				<div className={styles.content_side} ref={animHolderRef}>
					{animations[0].component}
				</div>
			)}
			{selectedOption !== undefined ? (
				<Overlay mobile={mobile}>{selectedOption.component}</Overlay>
			) : (
				<></>
			)}
		</div>
	);
};

export default LandingView;
