import { createTree } from "./tree";
import { CPromise } from "c-promise2";
import { createButterfliesManually } from "./static";
import { KWorker } from "../../../../util/web-worker";

const NUMBER_BUTTERFLYS = 79;

export function Scene(butterflys, canvas, after) {
  this.butterflys = butterflys;

  this.canvas = canvas;

  this.butterflyHolder = new DocumentFragment();

  const sceneThis = this;

  this.worker = new KWorker("./butterfly-worker.js");

  this.getCoordinatePromise = this.worker
    .connectAndSend("calculate", {
      count: NUMBER_BUTTERFLYS,
      width: this.canvas.width,
      height: this.canvas.height,
      dist: 50,
    })
    .then((ev) => ev.data.payload);

  this.cleanup = () => {
    window.onkeydown = null;
    this.worker.terminate();
  };

  this.offsetLeftPromise = this.getOffsetLeft().then((entry) => {
    this.holderOffsetLeft = entry[0].boundingClientRect.left;
    return entry[0].boundingClientRect.left;
  });

  const fn = function (e) {
    if (e.key === "Enter") {
      sceneThis._promise.cancel();

      sceneThis.endState();
      sceneThis.skipped = true;
      sceneThis.finished = true;

      after();
      sceneThis.cleanup();
    }
  };

  window.onkeydown = fn;
}

Scene.prototype.endState = async function () {
  this.canvas.other[0].current.innerText = "";
  this.canvas.other[2].current.innerText = "";

  this.perchLocations = [];
  this.butterflys = [];
  this.pcb = undefined;

  const doc = createTree(this);
  this.canvas.other[2].current.append(doc);
  this.canvas.other[2].current.classList.remove("treeup");
  this.canvas.other[1].current.classList.remove("treeshake");
  return createButterfliesManually(this);
};

Scene.prototype.getOffsetLeft = function () {
  return new CPromise((resolve, reject) => {
    const callback = (entries) => {
      resolve(entries);
    };

    const observer = new IntersectionObserver(callback);

    observer.observe(this.canvas.other[5].current);
  });
};

Scene.prototype.positions = function () {
  return new CPromise((resolve, reject) => {
    const callback = (entries) => {
      resolve(entries);
    };

    const observer = new IntersectionObserver(callback);

    for (let i = 0; i < this.butterflys.length; i += 1) {
      observer.observe(this.butterflys[i].leftWingStart);
      observer.observe(this.butterflys[i].rightWingStart);
    }
  });
};

Scene.prototype.setPerchLocations = function (els) {
  this.pcb = new CPromise((resolve, reject) => {
    const callback = (entries) => {
      this.perchLocations = entries;
      resolve(entries);
    };

    const observer = new IntersectionObserver(callback);

    for (let i = 0; i < els.length; i += 1) {
      observer.observe(els[i]);
    }
  });
};
