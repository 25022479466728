import React from "react";
import styles from "../menu.module.css";

const ListItem = ({ setSelectedOption, isSelectedOption, option }) => {
  return (
    <div
      onClick={() =>
        setSelectedOption(isSelectedOption(option) ? undefined : option)
      }
      className={styles.menu_option}
    >
      <div className={styles.scrim} />
      <img
        src={option.src}
        className={
          !isSelectedOption(option)
            ? styles.menu_option_image
            : styles.menu_option_image_selected
        }
        alt={"qbist"}
      />
      <div className={styles.option_text}>
        <p
          style={{
            zIndex: 1000,
          }}
        >
          {option.name}
        </p>
      </div>
    </div>
  );
};

export default ListItem;
