import { CPromise } from "c-promise2";

const handleSceneNotFinished = async (scene) => {
  // Cancel the animation sequence promise
  scene._promise.cancel();

  // Set new offsetLeft
  scene.offsetLeftPromise = Promise.resolve(
    scene.canvas.other[5].current.offsetLeft
  );

  // Hide the menu
  window.dispatchEvent(new CustomEvent("_unhide_menu"));

  // Run end state and mark the animation as finished.
  await scene.endState();
  scene.skipped = true;
  scene.finished = true;
};

const handleSceneFinished = (scene) => {
  if (scene.resizePromise !== undefined) scene.resizePromise.cancel();
  scene.resizePromise = new CPromise((resolve, reject, { onCancel }) => {
    onCancel(() => {
      clearTimeout(scene.debounce);
    });
    return scene.getOffsetLeft().then((d) => resolve(d));
  }).then(
    () =>
      new CPromise((resolve, reject, { onCancel }) => {
        onCancel(() => {
          if (scene.resizeFlightAnimation !== undefined) {
            scene.resizeFlightAnimation.cancel();
            clearTimeout(scene.debounce);
          }
        });
        scene.debounce = setTimeout(async () => {
          for (let i = 0; i < scene.perches.length; i += 1) {
            const butterfly = scene.butterflys[i];

            scene.resizeFlightAnimation = butterfly.parent.animate(
              {
                translate: [
                  `${Math.round(scene.perches[i].offsetLeft - 5)}px ${
                    scene.perches[i].offsetTop - 3
                  }px`,
                ],
              },
              {
                iterations: 1,
                fill: "forwards",
                duration: 1,
                timing: "ease",
              }
            );

            scene.resizeFlightAnimation.finished
              .then(() => resolve())
              .catch((err) => err);
          }
        }, 300);
      })
  );
};

export const setResizeHandler = (scene) => {
  window.onresize = async function () {
    if (!scene.finished) return handleSceneNotFinished(scene);
    handleSceneFinished(scene);
  };
};
