const KWorker = function (src) {
	this.src = src;
};

KWorker.prototype.createPromise = function () {
	return new Promise((resolve, reject) => {
		this.worker.onmessage = (ev) => {
			resolve(ev);
		};
		this.worker.onerror = (err) => reject(err);
	});
};

KWorker.prototype.connect = function (send = "beat") {
	this.worker = new Worker(this.src);

	const responsePromise = this.createPromise();

	this.worker.postMessage({ msg: send });

	return responsePromise;
};

KWorker.prototype.connectAndSend = function (
	msg,
	data,
	transferable = [],
	canvas = undefined
) {
	this.worker = new Worker(this.src);

	const responsePromise = this.createPromise();

	this.worker.postMessage({ msg, body: data, canvas: canvas }, transferable);

	return responsePromise;
};

KWorker.prototype.send = function (msg, data, transferable = []) {
	if (this.worker === undefined) {
		console.warn("web worker broken... again");
		return Promise.resolve();
	}
	const responsePromise = this.createPromise();
	this.worker.postMessage({ msg, body: data }, transferable);
	return responsePromise;
};

KWorker.prototype.sendWithCanvas = function (
	msg,
	data,
	offscreen,
	transferable = []
) {
	const responsePromise = this.createPromise();
	this.worker.postMessage({ msg, body: data, canvas: offscreen }, transferable);
	return responsePromise;
};

KWorker.prototype.terminate = function () {
	if (this.worker === undefined) return;
	this.worker.terminate();
};

const WorkerGroup = function (src, n) {
	this.src = src;
	this.n = n;
};

WorkerGroup.prototype.init = function () {
	this.workers = Array.from(
		{ length: this.n },
		(_, i) => new KWorker(this.src)
	);
};

WorkerGroup.prototype.get = function (i) {
	return this.workers[i];
};

WorkerGroup.prototype.sendToAll = function (fn) {
	return Promise.all(
		this.workers.map((worker, index) => worker.send(...fn(index)))
	);
};

WorkerGroup.prototype.sendToAllBatched = async function (batch, fn) {
	let i = 0;
	const result = [];
	while (i < this.workers.length) {
		const next = i + batch - 1;

		const res = await Promise.all(
			this.workers
				.slice(i, next + 1)
				.map((worker, index) => worker.send(...fn(index)))
		);

		result.push(res);

		i += batch;
	}

	return Promise.all(result.flat());
};

WorkerGroup.prototype.connectAndSendToAll = function (fn) {
	return Promise.all(
		this.workers.map((worker, index) => worker.connectAndSend(...fn(index)))
	);
};

WorkerGroup.prototype.terminate = function (fn) {
	return this.workers.map((worker, index) => worker.terminate());
};

export { KWorker, WorkerGroup };
