export const logo =
  `_/\\\\\\______________/\\\\\\_____/\\\\\\\\\\\\\\\\\\\\\\___        
_\\/\\\\\\_____________\\/\\\\\\___/\\\\\\/////////\\\\\\_       
 _\\/\\\\\\_____________\\/\\\\\\__\\//\\\\\\______\\///__      
  _\\//\\\\\\____/\\\\\\____/\\\\\\____\\////\\\\\\_________     
   __\\//\\\\\\__/\\\\\\\\\\__/\\\\\\________\\////\\\\\\______    
    ___\\//\\\\\\/\\\\\\/\\\\\\/\\\\\\____________\\////\\\\\\___   
     ____\\//\\\\\\\\\\\\//\\\\\\\\\\______/\\\\\\______\\//\\\\\\__  
      _____\\//\\\\\\__\\//\\\\\\______\\///\\\\\\\\\\\\\\\\\\\\\\/___ 
       ______\\///____\\///_________\\///////////_____`.split("");

export const logoSpaces = `_       ______________       _____                       ___        
       _         _____________         ___                      _       
        _         _____________         __          ______     __      
         _          ____       ____       ____            _________     
          __          __           __       ________            ______    
           ___                               ____________            ___   
            ____                            ______       ______          __  
             _____          __          ______                            ___ 
              ______     ____     _________             _____`;

export const tree =
  "                                                         .\n                                              .         ;  \n                 .              .              ;%     ;;   \n                   ,           ,                :;%  %;   \n                    :         ;                   :;%;'     .,   \n           ,.        %;     %;            ;        %;'    ,;\n             ;       ;%;  %%;        ,     %;    ;%;    ,%'\n              %;       %;%;      ,  ;       %;  ;%;   ,%;' \n               ;%;      %;        ;%;        % ;%;  ,%;'\n                `%;.     ;%;     %;'         `;%%;.%;'\n                 `:;%.    ;%%. %@;        %; ;@%;%'\n                    `:%;.  :;bd%;          %;@%;'\n                      `@%:.  :;%.         ;@@%;'   \n                        `@%.  `;@%.      ;@@%;         \n                          `@%%. `@%%    ;@@%;        \n                            ;@%. :@%%  %@@%;       \n                              %@bd%%%bd%%:;     \n                                #@%%%%%:;;\n                                %@@%%%::;\n                                %@@@%(o);  . '         \n                                %@@@o%;:(.,'         \n                            `.. %@@@o%::;         \n                               `)@@@o%::;         \n                                %@@(o)::;        \n                               .%@@@@%::;         \n                               ;%@@@@%::;.          \n                              ;%@@@@%%:;;;. \n                          ...;%@@@@@%%:;;;;,..  ";

export function Character(ref, parent, character, coords = { x: 0, y: 0 }) {
  this.ref = ref;

  this.charWrapper = this.ref.children[0];

  this.coords = coords;
  this.character = character;

  this.parent = parent;
}

Character.prototype.updateCoords = function () {
  this.coords = this.ref.getBoundingClientRect();
};

Character.prototype.moveTo = function (x, y, callback) {
  this.ref.ontransitionend = () => {
    callback();
  };

  this.ref.style.transform = `translate(${x}px, ${y}px)`;
};

const initLogo = (ref, all, parent) => {
  const c = [...ref.children[0].children];

  for (let i = 0; i < c.length; i += 1) {
    const el = c[i];
    if (el.id === "hideme") continue;
    all.push(
      new Character(el, parent, el.innerText, {
        x: el.offsetWidth,
        y: el.offsetHeight,
      })
    );
  }
};

export function TextCanvas(ref, ...other) {
  this.ref = ref;

  this.other = other;

  this.all = [];

  this.width = document.body.clientWidth;
  this.height = document.body.clientHeight;

  this.animating = false;

  initLogo(ref, this.all, this);
}

TextCanvas.prototype.setAnimation = function (animation, after) {
  this.animating = true;
  return animation(this, after);
};

TextCanvas.prototype.updateCoords = function () {
  for (let i = 0; i < this.all.length; i += 1) this.all[i].updateCoords();
};
