import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	Dialog,
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Select,
	Slider,
	TextField,
	Typography,
} from "@mui/material";
import { lch, hsl, rgb } from "d3-color";
import { drawFractalSync } from "./fractal";
import {
	generateColorHSL,
	generateColorLCH,
	generateColorRGB,
	generateColorTrippy,
} from "./color/colorutil";
import { adjustAspectRatio } from "./util";

const algorithms = {
	hsl(value) {
		return hsl((value + 0.8) * 360, 1, 0.5).formatHex();
	},
	lch(value) {
		return lch(60, 80, value * 360).formatHex();
	},
	rgb(value) {
		return rgb(value * 255, value * 255, value * 255).formatHex();
	},
	trippy(value) {
		return rgb(value * 255, value * 255, value * 255).formatHex();
	},
};

const DEFAULT_RANGE = {
	realStart: -1,
	realEnd: 1,
	imagStart: 1.5,
	imagEnd: -1.5,
	zoom: [3.4, 3.4],
};

const generationAlgorithims = {
	hsl: generateColorHSL,
	lch: generateColorLCH,
	rgb: generateColorRGB,
	trippy: generateColorTrippy,
};

const generateColorArr = (fn, maxIters) =>
	Array.from({ length: maxIters + 1 }, (_, i) => fn(i, maxIters));

const SettingsDialog = ({ colorRef, IterRef }) => {
	const canvasRef = useRef(null);
	const fractalCanvas = useRef(null);

	const [color, setColor] = useState("hsl");

	const drawToCanvas = (fn, c) => {
		const ctx = canvasRef.current.getContext("2d");

		canvasRef.current.width = canvasRef.current.clientWidth;
		canvasRef.current.height = canvasRef.current.offsetHeight;

		const w = canvasRef.current.width;
		const h = canvasRef.current.height;

		for (let x = 0; x <= w; x += 1) {
			ctx.fillStyle = fn(x / w);
			ctx.fillRect(x, 0, 1, h);
		}

		const fCanvas = fractalCanvas.current;
		fCanvas.width = w;

		if (!generationAlgorithims[c]) return;

		const range = { ...DEFAULT_RANGE };
		adjustAspectRatio(range, fCanvas);

		const imageData = new ImageData(fCanvas.width, fCanvas.height);

		const arr = generateColorArr(generationAlgorithims[c], IterRef.current);
		drawFractalSync(range, arr, imageData, w, fCanvas.height, IterRef.current);

		fCanvas.getContext("2d").putImageData(imageData, 0, 0);
		colorRef.current = arr;
		setColor(c);
	};

	const handleColorChange = (e) => {
		const color = e.target.value;

		drawToCanvas(algorithms[color], color);
	};

	useEffect(() => {
		drawToCanvas(algorithms[color], color);
	}, []);

	return (
		<Box
			sx={{ width: "100%", height: "80vh", background: "rgb(32, 32, 32)" }}
			py={3}
		>
			<Grid container xs={12} alignItems="start" justifyContent="center">
				<Grid container xs={11} rowGap={3}>
					<Grid
						container
						xs={12}
						justifyContent={"space-between"}
						alignItems="center"
					>
						<Typography fontSize={24}>Color</Typography>
						<Select
							value={color}
							sx={{ zIndex: "30000000", background: "white" }}
							size="small"
							onChange={handleColorChange}
						>
							<MenuItem value={"hsl"}>HSL</MenuItem>
							<MenuItem value={"lch"}>LCH</MenuItem>
							<MenuItem value={"rgb"}>Greyscale</MenuItem>
							<MenuItem value={"trippy"}>Trippy</MenuItem>
						</Select>
					</Grid>
					<Grid container xs={12}>
						<canvas
							style={{
								width: "100%",
								height: "40px",
								border: "1px solid white",
							}}
							ref={canvasRef}
						></canvas>
					</Grid>
					<Grid container xs={12}>
						<canvas
							ref={fractalCanvas}
							width="100"
							height="200"
							style={{ border: "1px solid white" }}
						></canvas>
					</Grid>
					<Typography fontSize={24}>Quality</Typography>

					<Grid container xs={12} justifyContent={"center"}>
						<Grid item xs={11}>
							<Slider
								defaultValue={IterRef.current / 100}
								min={1}
								max={150}
								onChangeCommitted={(e, val) => {
									console.log(val);
									IterRef.current = val * 100;
									drawToCanvas(algorithms[color], color);
								}}
								valueLabelDisplay="auto"
								valueLabelFormat={(x) => String(x * 100)}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SettingsDialog;
