import { showTree } from "./tree";
import { Scene } from "./scene";
import { CPromise } from "c-promise2";
import {
  initButterflys,
  createButterflys,
  distributeButterflys,
} from "./butterfly";
import { removeUnused, createPaths, popMenu } from "./util";
import { setResizeHandler } from "./resize";

export const birdScene = (canvas, after = () => {}) => {
  const scene = new Scene([], canvas, after);

  setResizeHandler(scene);

  scene._promise = new CPromise((resolve, reject, { onCancel }) =>
    createButterflys(canvas, scene).then((scene) => resolve(scene))
  )
    .then(distributeButterflys)
    .then(initButterflys)
    .then(showTree)
    .then(removeUnused)
    .then(createPaths)
    .then(popMenu)
    .then(after);

  return scene._promise;
};
