const MAX_ITER = 10_000;
const THRESHOLD = 16;

const getColor = (iterations) => {
	if (iterations >= MAX_ITER) return 0;

	return (iterations * iterations + 1) % 255;
};

export function adjustAspectRatio(range, canvas) {
	const ratio =
		Math.abs(range.realEnd - range.realStart) /
		Math.abs(range.imagEnd - range.imagStart);
	const sratio = canvas.width / canvas.height;
	if (sratio > ratio) {
		const xf = sratio / ratio;
		range.realStart *= xf;
		range.realEnd *= xf;
		//   zoom[0] *= xf;
	} else {
		const yf = ratio / sratio;
		range.imagStart *= yf;
		range.imagEnd *= yf;
		//   zoom[1] *= yf;
	}
}

//canvas, canvasPosition, offsetLeft, range, colors, width, height
export const drawFractal = (
	canvasRef,
	range,
	botRef,
	colors,
	iter,
	adjust = false
) => {
	const canvas = canvasRef.current;

	const { clientWidth: width, clientHeight: height } = canvas;

	if (adjust) adjustAspectRatio(range, { width, height });

	const { x: offsetLeft } = canvas.getBoundingClientRect();

	console.timeEnd("Fractal");
	console.time("workers");

	botRef.current
		.sendToAllBatched(16, (index) => {
			return [
				"draw",
				{
					workerName: `worker-${index}`,
					args: [offsetLeft, range, colors, width, height, iter || 10_000],
				},
			];
		})
		.then((data) => {
			console.timeEnd("workers");
			const averageCalcTime =
				data.map((n) => n.data.elapsed).reduce((a, b) => a + b) / data.length;
			const averageDrawTime =
				data.map((n) => n.data.draw).reduce((a, b) => a + b) / data.length;
			const averageTotal =
				data.map((n) => n.data.total).reduce((a, b) => a + b) / data.length;

			console.log("Calc time", averageCalcTime);
			console.log("Draw time", averageDrawTime);
			console.log("Average time", averageTotal);
		});
};

export const downloadCanvases = (canvasRef, gridLength) => {
	const { clientHeight } = canvasRef.current;
	const { clientWidth: sectionWidth, clientHeight: sectionHeight } =
		canvasRef.current.children[0];

	const clientWidth = gridLength * sectionWidth;

	const offscreen = new OffscreenCanvas(clientWidth, clientHeight);

	let x = 0,
		y = 0;
	for (let i = 0; i < canvasRef.current.children.length; i += 1) {
		const canvas = canvasRef.current.children[i];
		offscreen
			.getContext("2d")
			.drawImage(canvas, x, y, sectionWidth, sectionHeight);

		x += sectionWidth;
		if (x >= clientWidth) {
			x = 0;
			y += sectionHeight;
		}
	}

	const link = document.createElement("a");
	return offscreen.convertToBlob().then((b) => {
		const url = URL.createObjectURL(b);
		link.href = url;
		link.download = "fractal-generation.png";
		link.click();
	});
};
