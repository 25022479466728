import Complex from "complex.js";

const complex = (x, y, WIDTH, HEIGHT, RE_START, RE_END, IM_START, IM_END) => {
  const re = RE_START + (x / WIDTH) * (RE_END - RE_START);
  const im = IM_START + (y / HEIGHT) * (IM_END - IM_START);
  const c = { x: re, y: im };
  return c;
};

const THRESHOLD = 16;

function mandelbrot(c, MAX_ITER) {
  let x0 = c.x;
  let y0 = c.y;

  let x = 0;
  let y = 0;

  let x2 = 0;
  let y2 = 0;

  let iter = 0;

  while (x2 + y2 <= THRESHOLD && iter < MAX_ITER) {
    y = (x + x) * y + y0;
    x = x2 - y2 + x0;

    x2 = x * x;
    y2 = y * y;

    iter += 1;
  }

  if (iter === MAX_ITER) return { iter: MAX_ITER };

  return { iter, x: x, y: y };
}

const setPixel = (data, index, r, g, b, a = 255) => {
  data.data[index] = r;
  data.data[index + 1] = g;
  data.data[index + 2] = b;
  data.data[index + 3] = a;
};

const linear_interpolation = (color1, color2, t) => {
  return color1 * (1 - t) + color2 * t;
};

export const drawFractalSync = (
  range,
  colors,
  imgData,
  width,
  height,
  MAX_ITER
) => {
  const start = Date.now();
  let xb = 0;
  for (let x = 0; x < width; x += 1) {
    let yb = 0;
    for (let y = 0; y < height; y += 1) {
      const c = complex(
        x,
        y,
        width,
        height,
        range.realStart,
        range.realEnd,
        range.imagStart,
        range.imagEnd
      );

      let { iter: m, x: x1, y: y1 } = mandelbrot(c, MAX_ITER);

      if (m < MAX_ITER) {
        const log_zn = Math.log(x1 * x1 + y1 * y1) / 2;
        const nu = Math.log(log_zn / Math.log(2)) / Math.log(2);

        m = m + 1 - nu;
      }

      const pixel = 4 * (xb + yb * imgData.width);

      const color =
        m === MAX_ITER
          ? MAX_ITER
          : Math.round(
              360 * linear_interpolation(Math.floor(m), Math.ceil(m), m % 1)
            ) % MAX_ITER;

      setPixel(imgData, pixel, ...[...colors[color], 255]);
      yb += 1;
    }
    xb += 1;
  }
  return Date.now() - start;
};
