import {
	Box,
	Button,
	Dialog,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Select,
	Slider,
	TextField,
	Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/Download";
import React, { useEffect, useRef, useState } from "react";
import SettingsDialog from "./dialog";

const Overlay = ({
	onReset,
	open,
	setOpen,
	colorRef,
	handleDownload,
	IterRef,
}) => {
	const tutorialRef = useRef(null);

	useEffect(() => {
		const timer = setTimeout(() => {
			tutorialRef.current.style.opacity = 0;
			tutorialRef.current.animate([{ opacity: 1 }, { opacity: 0 }], {
				duration: 2500,
				easing: "ease",
			});
		}, 10_000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				fullWidth
				sx={{ width: "70vw", marginLeft: "30vw" }}
			>
				<SettingsDialog colorRef={colorRef} IterRef={IterRef} />
			</Dialog>
			<div
				style={{
					position: "absolute",
					zIndex: 10,
					padding: "20px",
					pointerEvents: "none",
				}}
			>
				<Typography fontSize={30} ref={tutorialRef}>
					Click anywhere on the fractal, move your mouse,
					<br /> then click anywhere again to zoom in
				</Typography>
			</div>
			<div
				style={{
					zIndex: 10,
					position: "absolute",
					top: 0,
					left: 0,
					pointerEvents: "none",
					display: "flex",
					width: "100%",
					height: "100%",
					justifyContent: "center",
					alignItems: "flex-end",
				}}
			>
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						padding: "9px",
						alignItems: "center",
					}}
				>
					<Button
						variant="contained"
						style={{
							background: "rgba(0, 0, 0, 0.4)",
							pointerEvents: "all",
						}}
						onClick={onReset}
					>
						Reset
					</Button>
					<Grid
						container
						alignItems="baseline"
						xs="auto"
						style={{ background: "rgba(0, 0, 0, 0.1)", borderRadius: "7px" }}
					>
						<IconButton
							style={{ pointerEvents: "all", color: "white" }}
							onClick={handleDownload}
						>
							<DownloadIcon />
						</IconButton>
						<IconButton
							style={{ pointerEvents: "all", color: "white" }}
							onClick={() => setOpen(true)}
						>
							<SettingsIcon />
						</IconButton>
					</Grid>
				</div>
			</div>
		</>
	);
};

export default Overlay;
