import React from "react";
import styles from "../menu.module.css";
import ListItem from "./listItem";

const Category = ({
  setSelectedOption,
  isSelectedOption,
  options,
  title,
  hidden,
}) => {
  return (
    <div className={styles.menu} style={{ opacity: hidden ? 0 : 1 }}>
      <div className={styles.menu_title}>
        <p>{title}</p>
        <hr />
      </div>
      <div className={styles.link_options}>
        {options.map((option, index) => (
          <ListItem
            key={`${option.name}-${index}__`}
            option={option}
            setSelectedOption={setSelectedOption}
            isSelectedOption={isSelectedOption}
          />
        ))}
      </div>
    </div>
  );
};

export default Category;
