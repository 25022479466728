export const tree =
  "                                                         .\n                                              .         ;  \n                 .              .              ;%     ;;   \n                   ,           ,                :;%  %;   \n                    :         ;                   :;%;'     .,   \n           ,.        %;     %;            ;        %;'    ,;\n             ;       ;%;  %%;        ,     %;    ;%;    ,%'\n              %;       %;%;      ,  ;       %;  ;%;   ,%;' \n               ;%;      %;        ;%;        % ;%;  ,%;'\n                `%;.     ;%;     %;'         `;%%;.%;'\n                 `:;%.    ;%%. %@;        %; ;@%;%'\n                    `:%;.  :;bd%;          %;@%;'\n                      `@%:.  :;%.         ;@@%;'   \n                        `@%.  `;@%.      ;@@%;         \n                          `@%%. `@%%    ;@@%;        \n                            ;@%. :@%%  %@@%;       \n                              %@bd%%%bd%%:;     \n                                #@%%%%%:;;\n                                %@@%%%::;\n                                %@@@%(o);  . '         \n                                %@@@o%;:(.,'         \n                            `.. %@@@o%::;         \n                               `)@@@o%::;         \n                                %@@(o)::;        \n                               .%@@@@%::;         \n                               ;%@@@@%::;.          \n                              ;%@@@@%%:;;;. \n                          ...;%@@@@@%%:;;;;,..  ";

export const removeUnused = (scene) => {
  const needed =
    scene.perchLocations.length +
    [...scene.canvas.other[3].current.children].filter(
      (el) => el.getAttribute("flyto") === "true"
    ).length +
    9;

  const toDelete = scene.butterflys.slice(needed);

  for (let i = 0; i < toDelete.length; i += 1) {
    toDelete[i].parent.remove();
    toDelete[i].deleted = true;
  }

  return Promise.resolve(scene);
};

const getLetterLocations = (scene) => {
  const els = [...scene.canvas.other[3].current.children[0].children].filter(
    (el) => el.getAttribute("flyto") === "true"
  );

  return els.map((n) => ({
    x: n.offsetLeft,
    y: n.offsetTop + n.offsetHeight / 2,
    draw: false,
    target: n,
  }));
};

export const createPaths = async (scene) => {
  const to = [
    ...scene.perchLocations.map((n) => ({
      x: n.boundingClientRect.x - 5,
      y: n.boundingClientRect.y - 3,
      draw: true,
    })),
    ...getLetterLocations(scene),
  ];

  const animHolderOffsetLeft = scene.holderOffsetLeft;

  const letterPromises = [];

  let letterCount = 0;
  for (let i = 0; i < to.length; i += 1) {
    const butterfly = scene.butterflys[i];

    if (butterfly.deleted === true) continue;

    const animation = butterfly.parent.animate(
      [{ translate: `${to[i].x - animHolderOffsetLeft}px ${to[i].y}px` }],
      {
        duration:
          to[i].draw !== true
            ? 500 + letterCount * 100
            : Math.round(1000 + Math.random() * 1500),
        iterations: 1,
        fill: "both",
        easing: "ease",
      }
    );

    const promise = animation.finished.then(() => {
      if (to[i].draw === true) {
        butterfly.endAnimation();
      } else {
        butterfly.parent.remove();
        butterfly.deleted = true;
        const showLetterAnimation = to[i].target.animate(
          [
            { opacity: 0, transform: "translateY(-5px)" },
            { opacity: 1, transform: "translateY(0)" },
          ],
          { duration: 300, iterations: 1, fill: "forwards" }
        );
        return showLetterAnimation.finished;
      }
    });

    if (!to[i].draw) {
      letterCount += 1;
      letterPromises.push(promise);
    }
  }

  await Promise.all(letterPromises);

  return scene;
};

export const popMenu = (scene) => {
  const toShow = scene.canvas.other[3].current.children;
  toShow[1].style.opacity = 1;
  [...toShow[2].children[0].children].forEach((n) => (n.style.opacity = 1));
  scene.canvas.other[4].current = true;

  scene.finished = true;
  scene.skipped = true;
  scene.cleanup();

  return scene;
};
