import React, { useState, useEffect } from "react";
import styles from "./menu.module.css";
import { socials } from "../landing/ButterflyIntro";

const ListOptions = ({ options, selectedOption, setSelectedOption, title }) => {
  const isSelectedOption = (option) => selectedOption?.id === option.id;

  return (
    <div className={styles.menu} style={{ width: "90%" }}>
      <div className={styles.menu_title} style={{ textAlign: "center" }}>
        <p>{title}</p>
        <hr />
      </div>
      <div className={styles.link_options}>
        {options.map((option, index) => (
          <div
            key={`option-${index}-${option.name}`}
            onClick={() =>
              setSelectedOption(isSelectedOption(option) ? undefined : option)
            }
            className={styles.menu_option}
            style={{ height: "40px" }}
          >
            <div className={styles.scrim} />
            <img
              src={option.src}
              className={styles.menu_option_image_selected}
              alt={"qbist"}
            />
            <div className={styles.option_text}>
              <p
                style={{
                  zIndex: 1000,
                }}
              >
                {option.name}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const MobileMenu = ({ options, setSelectedOption, selectedOption }) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        opacity: selectedOption !== undefined ? 0 : 1,
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          className={styles.letter}
          style={{ opacity: 1, textAlign: "center" }}
        >
          wiseshiba
        </p>
        <ListOptions
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          title="Image Generators"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "end",
          width: "100%",
          padding: "15px 0",
        }}
      >
        {socials.map((n, i) => (
          <img
            className="social_img"
            style={{
              transition: "0.5s opacity",
              cursor: "pointer",
              width: "45px",
              height: "45px",
            }}
            src={n.src}
            name={i}
            ind={i}
            key={n.name}
            flyto="true"
            onClick={() => window.open(n.url)}
            alt={n.name}
            to={n.name}
          />
        ))}
      </div>
    </div>
  );
};

export default MobileMenu;
