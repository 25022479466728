import React, {
  createRef,
  forwardRef,
  useEffect,
  useContext,
  useRef,
  useState,
} from "react";
import { Buffer } from "buffer";
import styles from "./qbist.module.css";
import { KWorker } from "../../../util/web-worker";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const Option = ({ title, input }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <p>{title}:</p>
      {input}
    </div>
  );
};

const RNDR_VP_PERCENTAGE = [0.3, 0.55];
const GRID_PERCENTAGE = [0.05, 0.08];

export const QbistGenerator = ({ mobile }) => {
  const [metadata, setMetadata] = useState({});
  const [generationMode, setGenerationMode] = useState(0);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const onSafariMobile =
    window.navigator.userAgent.includes("Safari") && mobile;

  const optionsRef = useRef({
    quality: 4,
    aa: false,
    needsResize: false,
    canvasDimensions: [],
  });

  const workerRef = useRef();

  const canvasGridRefs = useRef([]);
  const canvasRef = useRef(null);

  const holderRef = useRef(null);

  const gridHolderRef = useRef(null);
  const inputRef = useRef(null);

  const copySeedTextRef = useRef(null);

  const updateMetadata = (ev) => {
    const newMetadata = { ...metadata };
    newMetadata.seed = Buffer.from(ev.data.payload[0]).toString("base64");
    newMetadata.object = ev.data.payload[1];

    setMetadata(newMetadata);
  };

  const renderQbist = () => {
    setLoading(true);

    if (onSafariMobile) {
      return workerRef.current
        .send("getData", { ...optionsRef.current })
        .then((ev) => {
          setLoading(false);

          canvasRef.current
            .getContext("2d")
            .putImageData(ev.data.imageData, 0, 0);
        });
    } else {
      return workerRef.current
        .send("create", { ...optionsRef.current })
        .then((ev) => {
          setLoading(false);
          updateMetadata(ev);
        });
    }
  };

  const updateQbist = (mtd) => {
    return workerRef.current
      .send("update", { ...optionsRef.current, seed: mtd.object })
      .then((ev) => {
        setLoading(false);
        if (mtd.seed === metadata.seed) return;
        updateMetadata(ev);
      });
  };

  const renderQbistGrid = () => {
    return workerRef.current.send("grid", {}).then((ev) => {
      setMetadata((prev) => ({ ...prev, seeds: ev.data.seeds }));
    });
  };

  const initGridRendering = () => {
    const converted = canvasGridRefs.current.map((canvas) =>
      canvas.transferControlToOffscreen()
    );

    workerRef.current
      .send("initGrid", { items: converted }, converted)
      .then(renderQbistGrid);
  };

  const initTool = () => {
    workerRef.current = new KWorker("./qbist-worker.js");

    if (onSafariMobile) return workerRef.current.connect().then(renderQbist);

    const offscreen = canvasRef.current.transferControlToOffscreen();

    return workerRef.current
      .connect()
      .then((ev) =>
        workerRef.current.sendWithCanvas("init", {}, offscreen, [offscreen])
      )
      .then(renderQbist)
      .then(initGridRendering)
      .catch((err) => alert(err.message));
  };

  const handleGridClick = (index) => {
    setGenerationMode(0);
    setLoading(true);
    updateQbist({ object: metadata.seeds[index] }).then(() =>
      setLoading(false)
    );
  };

  const getRenderViewportDimensions = () => {
    const { clientWidth } = document.body;

    let rvWidth = clientWidth * RNDR_VP_PERCENTAGE[0];
    if (rvWidth > 600) rvWidth = 600;

    const rvHeight = rvWidth / 1.2;

    return {
      width: `${Math.round(rvWidth)}px`,
      height: `${Math.round(rvHeight)}px`,
    };
  };

  const updateOnResize = () => {
    const { width, height } = getRenderViewportDimensions();

    holderRef.current.style.width = width;
    holderRef.current.style.height = height;

    if (canvasRef.current === null) return;
    canvasRef.current.style.width = width;
    canvasRef.current.style.height = height;

    if (canvasGridRefs.current === null) return;

    let gridWidth = Math.round(GRID_PERCENTAGE[0] * document.body.clientWidth);
    let gridGap = Math.round(GRID_PERCENTAGE[1] * document.body.clientWidth);

    gridHolderRef.current.style.gridTemplateColumns = `repeat(4, ${gridGap}px)`;
    gridHolderRef.current.style.gridTemplateRows = `repeat(3, ${gridGap}px)`;

    canvasGridRefs.current.forEach((el) => {
      el.style.width = `${gridWidth}px`;
    });

    optionsRef.current.needsResize = true;
    optionsRef.current.canvasDimensions = [width, height];
  };

  useEffect(() => {
    const start = async () => initTool().catch((err) => err);

    start();

    return () => {
      workerRef.current.terminate();
    };
  }, []);

  const copySeed = () => {
    navigator.clipboard.writeText(metadata?.seed);
    copySeedTextRef.current.animate(
      [
        { opacity: 1, translate: "0px 0px" },
        { opacity: 0, translate: "0px 150%" },
      ],
      {
        duration: 600,
        iterations: 1,
      }
    );
  };

  const saveCanvas = () => {
    setDownloading(true);
    workerRef.current.send("download").then(({ data: { url } }) => {
      setDownloading(false);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${metadata.seed.slice(0, 10)}.png`;
      link.click();
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateOnResize);

    return () => {
      window.removeEventListener("resize", updateOnResize);
    };
  }, []);

  return (
    <div className={styles.qbist_holder}>
      <div className={styles.qbist_tool}>
        <Typography variant="h5" color="white" py={3}>
          QBist Generator
        </Typography>
        <div className={styles.leftright}>
          <div
            className={styles.render_view}
            style={{
              ...getRenderViewportDimensions(),
            }}
            ref={holderRef}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              {generationMode === 0 && loading ? (
                <CircularProgress variant="indeterminate" color="error" />
              ) : (
                <></>
              )}
            </div>
            <div
              className={styles.render_view_holder}
              style={
                generationMode === 1 || loading
                  ? { opacity: 0, pointerEvents: "none" }
                  : {}
              }
            >
              <QBistSingle
                ref={canvasRef}
                loading={loading}
                getRenderViewportDimensions={getRenderViewportDimensions}
              />
            </div>
            <div
              className={styles.render_view_holder}
              style={
                generationMode === 0
                  ? { opacity: 0, pointerEvents: "none" }
                  : {}
              }
            >
              <QBistGrid
                gridRef={canvasGridRefs}
                handleGridClick={handleGridClick}
                gridHolderRef={gridHolderRef}
              />
            </div>
          </div>
          <div className={styles.options}>
            <Option
              title="Generation Mode"
              input={
                <select
                  onChange={(e) => {
                    setGenerationMode(Number(e.target.value));
                  }}
                  value={generationMode}
                >
                  <option value="0">Single</option>
                  <option value="1">Grid</option>
                </select>
              }
            />
            <Option
              title="Quality"
              input={
                <input
                  type="range"
                  min="1"
                  max="5"
                  disabled={generationMode === 1}
                  defaultValue={4}
                  onChange={(e) => {
                    optionsRef.current.quality = e.target.value;
                    updateQbist(metadata);
                  }}
                ></input>
              }
            />
            <Option
              title="Antialiasing"
              input={
                <input
                  type="checkbox"
                  onChange={(e) => {
                    optionsRef.current.aa = e.target.checked;
                    updateQbist(metadata);
                  }}
                  disabled={generationMode === 1}
                ></input>
              }
            />
            <Option
              title="From Seed"
              input={
                <Grid
                  container
                  style={{ width: "70%" }}
                  alignItems="center"
                  gap={2}
                >
                  <Grid xs={8}>
                    <input style={{ width: "100%" }} ref={inputRef} />
                  </Grid>
                  <Grid>
                    <Button
                      style={{
                        width: "25%",
                        display: "inline-block",
                      }}
                      size="small"
                      variant="ws_secondary"
                      onClick={() => {
                        if (inputRef.current.value === "") return;

                        const decoded = [
                          ...Buffer.from(inputRef.current.value, "base64"),
                        ];

                        if (decoded.length !== 144) return;

                        updateQbist({
                          object: { name: "", parms: decoded },
                        }).catch((err) => err);
                      }}
                    >
                      Go
                    </Button>
                  </Grid>
                </Grid>
              }
            />
            <Button
              variant="ws_primary"
              style={{ marginTop: "auto", marginBottom: "10px" }}
              onClick={() => {
                if (generationMode === 1) renderQbistGrid();
                if (generationMode === 0) renderQbist();
              }}
            >
              Generate
            </Button>
          </div>
        </div>
        <Grid container width={1} alignItems="end" xs={12} py={2}>
          <Grid xs={8}>
            <p className={styles.metadata_title}>
              #{metadata.seed ? metadata?.seed.slice(0, 10) : ""}
            </p>
          </Grid>
          <Grid
            container
            alignItems={"end"}
            justifyContent="end"
            gap={1}
            xs={4}
          >
            <Grid style={{ position: "relative" }}>
              <Button variant="ws_secondary" onClick={copySeed} size="small">
                Copy Seed
              </Button>
              <p
                style={{
                  position: "absolute",
                  fontWeight: "bold",
                  fontSize: "13px",
                  opacity: 0,
                  textAlign: "center",
                  color: "lightgreen",
                  width: "100%",
                  pointerEvents: "none",
                }}
                ref={copySeedTextRef}
              >
                COPIED
              </p>
            </Grid>
            <Grid>
              <Button
                variant="ws_secondary"
                size="small"
                startIcon={<DownloadIcon />}
                onClick={saveCanvas}
                disabled={downloading || generationMode === 1}
              >
                Save Image
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <hr />
        <Grid container>
          <Typography color="#999999" fontSize={11}>
            Credits:{" "}
            <span
              onClick={() => window.open("https://sylphe.ch/", "_blank")}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              UJR
            </span>{" "}
            for HTML5 implementation, Jörn Loviscach for the QBist algorithim.
          </Typography>
        </Grid>
      </div>
    </div>
  );
};

const QBistSingle = forwardRef((props, ref) => {
  return (
    <canvas
      ref={ref}
      {...props.getRenderViewportDimensions()}
      style={{
        borderRadius: "7px",
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
      }}
    ></canvas>
  );
});

const QBistGrid = ({ gridRef, gridHolderRef, handleGridClick }) => {
  useEffect(() => {
    gridRef.current = gridRef.current.slice(0, 12);
  }, []);

  const getGridParentStyles = () => {
    let gridGap = Math.round(GRID_PERCENTAGE[1] * document.body.clientWidth);

    return {
      gridTemplateColumns: `repeat(4, ${gridGap}px)`,
      gridTemplateRows: `repeat(3, ${gridGap}px)`,
    };
  };

  const getGridItemStyles = () => {
    let gridWidth = Math.round(GRID_PERCENTAGE[0] * document.body.clientWidth);

    return { width: gridWidth, borderRadius: "7px" };
  };

  const renderGrid = () => {
    const cnvs = [];

    for (let i = 0; i < 12; i += 1) {
      cnvs.push(
        <canvas
          width="100px"
          height="100px"
          className={styles.grid_item}
          ref={(el) => (gridRef.current[i] = el)}
          onClick={() => handleGridClick(i)}
          key={`GRID-${i}`}
          style={getGridItemStyles()}
        />
      );
    }

    return cnvs;
  };
  return (
    <div
      className={styles.qbist_grid}
      ref={gridHolderRef}
      style={getGridParentStyles()}
    >
      {renderGrid()}
    </div>
  );
};
