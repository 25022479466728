import React, { useCallback, useEffect, useRef, useState } from "react";
import { downloadCanvases, drawFractal } from "./util";
import { WorkerGroup } from "../../../util/web-worker";
import MouseFollower from "./follower";
import ColorEditor from "./color/ColorEditor";
import { Button } from "@mui/material";
import { hsl } from "d3-color";
import Overlay from "./overlay";
import { debounce } from "lodash";

export const DEFAULT_RANGE = {
	realStart: -1.5,
	realEnd: 1,
	imagStart: 1.5,
	imagEnd: -1.5,
	zoom: [3.4, 3.4],
};

const generateColorHSL = (color, MAX_ITER) => {
	if (color === MAX_ITER) return [0, 0, 0];

	const { r, g, b } = hsl(360 * (color / MAX_ITER), 0.8, 0.5).rgb();

	return [r, g, b];
};

const FractalTool = () => {
	const canvasRef = useRef(null);
	const botRef = useRef(null);

	const [open, setOpen] = useState(false);
	const [screen, setScreen] = useState();

	const currentRange = useRef();

	const MAX_ITER = 10_000;
	const IterRef = useRef(MAX_ITER);

	const colors = useRef(
		Array.from({ length: MAX_ITER + 1 }, (_, i) =>
			generateColorHSL(i, MAX_ITER)
		)
	);

	const handleResize = () => {
		setScreen(document.body.clientWidth);
	};

	const resizeDebounce = useCallback(debounce(handleResize, 1000), []);

	useEffect(() => {
		window.addEventListener("resize", resizeDebounce);

		return () => window.removeEventListener("resize", resizeDebounce);
	}, []);

	const renderFractal = (range = DEFAULT_RANGE, adjust = false) => {
		drawFractal(
			canvasRef,
			range,
			botRef,
			colors.current,
			IterRef.current,
			adjust
		);

		currentRange.current = range;
	};

	const gridLength = window.navigator.userAgent.includes("Chrome") ? 6 : 16;

	const renderGrids = (slice) => {
		const height = document.body.offsetHeight;
		const width = Math.floor(document.body.offsetWidth * 0.7);

		const c = [];

		const gridSizeY = height / slice;
		const gridSizeX = width / slice;

		if (width > height || width === height) {
			for (let y = 0; y < height; y += gridSizeY) {
				for (let x = 0; x < width; x += gridSizeX) {
					c.push(
						<canvas
							width={gridSizeX}
							height={gridSizeY}
							style={{ background: "black", zIndex: "1" }}
						/>
					);
				}
			}
		}

		if (width < height) {
			for (let y = 0; y < width; y += gridSizeX) {
				for (let x = 0; x < height; x += gridSizeY) {
					c.push(
						<canvas
							width={gridSizeX}
							height={gridSizeY}
							style={{ background: "black", zIndex: "1" }}
						/>
					);
				}
			}
		}

		return c;
	};

	const startBots = (count) => {
		const botAmount = count;
		if (botRef?.current) {
			botRef?.current.terminate();
		}
		botRef.current = new WorkerGroup("./fractalWorker.js", botAmount);

		botRef.current.init();

		const canvases = canvasRef.current.children;

		botRef.current
			.connectAndSendToAll((index) => {
				const canvasPosition = canvases[index].getBoundingClientRect();

				const botCanvas = canvases[index].transferControlToOffscreen();

				return [
					"init",
					{
						workerName: `worker-${index}`,
						canvasPosition,
						iter: IterRef?.current || MAX_ITER,
					},
					[botCanvas],
					botCanvas,
				];
			})
			.then(() => renderFractal(DEFAULT_RANGE, true));
	};

	const handleZoomIn = (start, end) => {
		const range = currentRange.current;

		let x = Math.min(start[0], end[0]) + Math.abs(start[0] - end[0]) / 2.0;
		let y = Math.min(start[1], end[1]) + Math.abs(start[1] - end[1]) / 2.0;

		const dx =
			(range.realEnd - range.realStart) /
			(0.5 + (canvasRef.current.clientWidth - 1));
		const dy =
			(range.imagEnd - range.imagStart) /
			(0.5 + (canvasRef.current.clientHeight - 1));

		x = range.realStart + x * dx;
		y = range.imagStart + y * dy;

		const [xf, yf] = [
			Math.abs(Math.abs(start[0] - end[0]) / canvasRef.current.offsetWidth),
			Math.abs(Math.abs(start[1] - end[1]) / canvasRef.current.offsetHeight),
		];

		const newZoom = [range.zoom[0], range.zoom[1]];

		newZoom[0] *= Math.max(xf, yf);
		newZoom[1] *= Math.max(xf, yf);

		const newRange = {
			realStart: x - newZoom[0] / 2,
			realEnd: x + newZoom[0] / 2,
			imagStart: y - newZoom[1] / 2,
			imagEnd: y + newZoom[1] / 2,
			zoom: newZoom,
		};

		renderFractal(newRange, false);
	};
	useEffect(() => {
		if (canvasRef?.current) startBots(canvasRef.current.children.length);
		return () => {
			botRef.current.terminate();
		};
	}, [canvasRef]);

	const handleDownload = () => downloadCanvases(canvasRef, gridLength);

	return (
		<>
			<div style={{ position: "relative" }}>
				<Overlay
					onReset={() => renderFractal(DEFAULT_RANGE, true)}
					colorRef={colors}
					open={open}
					setOpen={setOpen}
					handleDownload={handleDownload}
					IterRef={IterRef}
				/>

				<MouseFollower
					canvasRef={canvasRef}
					handleZoom={handleZoomIn}
					open={open}
				/>
				<div ref={canvasRef} style={{ display: "flex", flexWrap: "wrap" }}>
					{renderGrids(gridLength)}
				</div>
			</div>
		</>
	);
};

export default FractalTool;
