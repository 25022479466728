import { Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";

const OFFSET_X = 20;
const OFFSET_Y = 20;

const MouseFollower = ({ canvasRef, handleZoom, open }) => {
	const followerRef = useRef(null);
	const pointsRef = useRef({ start: undefined, end: undefined });

	const isFollowing = useRef(false);
	const drawSquare = useRef(false);

	const overlayCanvasRef = useRef(null);
	const animationFrameRef = useRef(null);

	const startDrawingOverlay = () => {
		overlayCanvasRef.current.width = canvasRef.current.clientWidth;
		overlayCanvasRef.current.height = canvasRef.current.clientHeight;

		drawSquare.current = true;
	};

	const handleClick = ({ clientX, clientY }, off) => {
		const points = pointsRef.current;
		if (!points.start) {
			points.start = [clientX - off, clientY];
			return startDrawingOverlay();
		}

		if (!points.end) {
			points.end = [clientX - off, clientY];
			return zoom(points);
		}
	};

	const reset = () => {
		window.cancelAnimationFrame(animationFrameRef.current);
		overlayCanvasRef.current.width = 0;
		drawSquare.current = false;
		followerRef.current.style.opacity = 0;
		pointsRef.current = { start: undefined, end: undefined };
	};

	const zoom = (points) => {
		handleZoom(points.start, points.end);
		reset();
	};

	const drawZoomIndicator = ({ clientX, clientY }, offset) => {
		function animate() {
			const ctx = overlayCanvasRef.current.getContext("2d");

			overlayCanvasRef.current.width = overlayCanvasRef.current.width;
			ctx.beginPath();
			ctx.strokeStyle = "white";
			ctx.rect(
				...pointsRef.current.start,
				clientX - offset - pointsRef.current.start[0],
				clientY - pointsRef.current.start[1]
			);
			ctx.stroke();
		}

		animationFrameRef.current = window.requestAnimationFrame(animate);
	};

	const handleMouseOut = () => {
		isFollowing.current = false;
		window.cancelAnimationFrame(animationFrameRef.current);
		followerRef.current.style.opacity = 0;
	};

	const handleKeyPress = ({ keyCode }) => {
		// Escape
		if (keyCode === 27) {
			if (overlayCanvasRef.current) overlayCanvasRef.current.width = 0;

			pointsRef.current = { start: undefined, end: undefined };
			drawSquare.current = false;
		}
	};

	useEffect(() => {
		const ref = canvasRef?.current;
		const leftOffset = ref.getBoundingClientRect().x;
		ref.onmousemove = (e) => {
			followerRef.current.animate(
				[
					{
						translate: `${e.clientX + OFFSET_X - leftOffset}px ${
							e.clientY + OFFSET_Y
						}px`,
					},
				],
				{ duration: 1, iterations: 1, fill: "forwards" }
			);
			followerRef.current.children[0].textContent = `${Math.round(
				e.clientX - leftOffset
			)} ${e.clientY}`;
			isFollowing.current = true;

			if (drawSquare.current === true) drawZoomIndicator(e, leftOffset);
		};

		ref.onmousedown = (e) => {
			if (isFollowing.current) {
				handleClick(e, leftOffset);
			}
		};

		ref.onmouseout = () => {
			handleMouseOut();
		};

		ref.onmouseover = () => {
			followerRef.current.style.opacity = 1;
		};

		window.addEventListener("keyup", handleKeyPress);

		return () => {
			if (ref) {
				ref.onmousemove = null;
				ref.onclick = null;
				ref.onmousedown = null;

				window.removeEventListener("keyup", handleKeyPress);
			}
		};
	}, [canvasRef]);

	useEffect(() => {
		pointsRef.current = { start: undefined, end: undefined };
		drawSquare.current = false;
	}, []);

	useEffect(() => {
		if (overlayCanvasRef.current) overlayCanvasRef.current.width = 0;
	}, [overlayCanvasRef]);

	return (
		<>
			<canvas
				ref={overlayCanvasRef}
				style={{
					position: "absolute",
					zIndex: 10000 * Number(!open),
					top: 0,
					left: 0,
					pointerEvents: "none",
				}}
			></canvas>
			<div
				ref={followerRef}
				style={{
					position: "absolute",
					zIndex: 10000 * Number(!open),
					top: 0,
					left: 0,
					opacity: 0,
				}}
			>
				<Typography>0 0</Typography>
			</div>
		</>
	);
};

export default MouseFollower;
